export default function InitContactForm() {
    // Open the contact form popup
    document.querySelectorAll('.popup-opener').forEach(trigger => {
        trigger.addEventListener('click', (e: Event) => {
            const contactFormPopup = document.querySelector('.contact-form__popup')
            contactFormPopup?.classList.add('contact-form__popup--open')
            contactFormPopup?.classList.remove('contact-form__popup--closed')
        })
    })

    // Close the contact form popup
    document.querySelectorAll('.contact-form__popup-close-icon').forEach(closeIcon =>
        closeIcon.addEventListener('click', (e: Event) => {
            document.querySelectorAll('.contact-form__popup').forEach(contactFormPopup => {
                contactFormPopup.classList.remove('contact-form__popup--open')
                contactFormPopup.classList.add('contact-form__popup--closed')
            })
        }))
}
