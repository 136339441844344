export default function InitSubnavigation() {

    const treatmentBlock = document.querySelector('.block__treatment')

    const simulateClick = (headerMenuButton: HTMLElement, megamenuItem: HTMLElement) => {
        headerMenuButton.click()
        megamenuItem.click()
    }

    const closeSubnavigation = () => {
        document.querySelectorAll('.subnavigation__treatments').forEach(treatment => {
            treatment.classList.remove('subnavigation__treatments--open')
        })

        document.querySelectorAll('.subnavigation__items').forEach(item => {
            item.classList.remove('subnavigation__items--open')
        })

        treatmentBlock?.replaceChildren()

        const root = document.documentElement
        root?.classList.remove('subnavigation-open')
    }

    const openSubnavigation = (currentTarget: HTMLElement) => {
        const currentTreatments = currentTarget.querySelector('.subnavigation__treatments')
        currentTreatments?.classList.add('subnavigation__treatments--open')
        currentTarget.classList.add('subnavigation__items--open')

        var clone = currentTreatments?.cloneNode(true)
        if (clone) treatmentBlock?.appendChild(clone)
    }

    /**
     *  Open subnavigation if clicked on the subnavigation item title
     */
    document.querySelectorAll('.subnavigation__items')?.forEach(item => {
        item.addEventListener('click', (e: Event) => {
            const root = document.documentElement
            const currentTarget = e.currentTarget as HTMLElement

            if (!!root) {
                closeSubnavigation();

                root?.classList.add('subnavigation-open')

                openSubnavigation(currentTarget);
                /**
                *  Close subnavigation if clicked on the subnavigation close icon
                */
                treatmentBlock?.querySelectorAll('.subnavigation__treatment-close-icon')?.forEach(closeIcon => {
                    closeIcon.addEventListener('click', (e: Event) => {
                        closeSubnavigation();
                    })
                })
            }
        })
    })



    /**
     *  Open megamenu if clicked on the subnavigation item title on mobile
     */
    document.querySelectorAll('.subnavigation__item--menu-trigger').forEach(trigger => {
        const headerMenuButton = document.querySelector('.headermenu-button') as HTMLElement | null
        const megamenuItem = document.querySelector('.headerlink--subnavigation') as HTMLElement | null

        trigger.addEventListener('click', (e: Event) => {
            if (headerMenuButton && megamenuItem) {
                simulateClick(headerMenuButton, megamenuItem)
            }
        })
    })
}