import $ from 'jquery';

export default function InitVideo() {
    $(document).on('click', '.video', function () {
        if (!$(this).hasClass('video--is-playing')) {
            $(this).addClass('video--is-playing');
            let videoHtml = $(this).data('video');
            $(this).find('.video__container').append(videoHtml);
        }
    });
}
