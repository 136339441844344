export default function InitFooter() {
  const simulateClick = (headerMenuButton: HTMLElement, megamenuItem: HTMLElement) => {
    headerMenuButton.click()
    megamenuItem.click()
  }

  /**
  *  Open megamenu if clicked on the footer item title
  */
  document.querySelectorAll('.footer__menuItem-trigger').forEach(trigger => {
    const headerMenuButton = document.querySelector('.headermenu-button') as HTMLElement | null
    const megamenuItem = document.querySelector('.headerlink--subnavigation') as HTMLElement | null

    trigger.addEventListener('click', (e: Event) => {
      if (headerMenuButton && megamenuItem) {
        simulateClick(headerMenuButton, megamenuItem)
      }
    })
  })
}
