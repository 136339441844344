function handleBlur(
  heroImageText: Element | null,
  headerWrapper: Element | null,
  subnavigation: Element | null,
  subnavigationMobile: Element | null
  ) {
  const heroImageRect = heroImageText?.getBoundingClientRect(),
        headerWrapperRect = headerWrapper?.getBoundingClientRect(),
        subnavigationRect = subnavigation?.getBoundingClientRect(),
        subnavigationMobileRect = subnavigationMobile?.getBoundingClientRect();

  if (headerWrapperRect && heroImageRect && headerWrapperRect.bottom > heroImageRect.top) {
    // Intersecting with header
    headerWrapper?.classList.add('blur-backdrop');
  } else {
    headerWrapper?.classList.remove('blur-backdrop');
  }
  if (subnavigationRect && heroImageRect && subnavigationRect.top < heroImageRect.bottom) {
    // Intersecting with subnavigation
    subnavigation?.classList.add('blur-backdrop');
  } else {
    subnavigation?.classList.remove('blur-backdrop');
  }
  if (subnavigationMobileRect && heroImageRect && subnavigationMobileRect.top < heroImageRect.bottom) {
    // Intersecting with subnavigation
    subnavigationMobile?.classList.add('blur-backdrop');
  } else {
    subnavigationMobile?.classList.remove('blur-backdrop');
  }
}


export default function InitHeroImage() {
  const heroImageText = document.querySelector('.hero-section-image__text-content'),
        headerWrapper = document.querySelector('#header-wrapper'),
        subnavigation = document.querySelector('#subnavigation'),
        subnavigationMobile = document.querySelector('#subnavigation--mobile');

  window.addEventListener('load', function() {
    handleBlur(heroImageText, headerWrapper, subnavigation, subnavigationMobile);
  })

  window.addEventListener('resize', function() {
    handleBlur(heroImageText, headerWrapper, subnavigation, subnavigationMobile);
  })

  window.addEventListener('orientationchange', function() {
    handleBlur(heroImageText, headerWrapper, subnavigation, subnavigationMobile);
  })
}
