import InitAnchorLinks from './atoms/anchorLinks'
import InitAestheticHeader from './header'
import InitImageSlider from './content/imageSlider'
import InitReviewSlider from './content/reviewSlider'
import InitVideo from './atoms/video'
import InitPersonList from './content/person-list/personList'
import { initializeSelectMenu } from '../../shared/ts/select-menu/select-menu-init'
import InitCollapsible from './collapsible'
import InitSubnavigation from './subnavigation'
import InitMap from './content/map'
import InitContactForm from './content/contact-form'
import InitHeaderSiteIntro from './content/headersiteintro'
import InitFooter from './footer'
import InitHeroImage from './hero-section-image'
import InitRedirectHint from "./redirect-hint";
import InitPopUp from "./popup";
import InitNewsList from './content/newsList'

InitAnchorLinks()
InitRedirectHint()
InitAestheticHeader()
InitHeroImage()
InitVideo()
InitImageSlider()
InitReviewSlider()
InitPersonList()
initializeSelectMenu()
InitCollapsible()
InitSubnavigation()
InitMap()
InitContactForm()
InitHeaderSiteIntro()
InitFooter()
InitPopUp()
InitNewsList()
