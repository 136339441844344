import $ from 'jquery';

export default function InitAnchorLinks() {
    const hash = window.location.hash
    const target = $(hash)
    const headerHeight = 133 // todo read dynamic height
    const mobileHeaderHeight = 71 // todo read dynamic height
    // set offset to anchor because of sticky nav
    if (target && target.length && target.offset()) {
        const offset = target.offset()?.top || headerHeight
        $('html,body').clearQueue().stop().animate({
        scrollTop: offset - headerHeight, //offsets for fixed header
        }, 'linear')
    }

    // scroll to anchor if on same page
    $('a[href^="#"]').on('click', function () {
        const href = $(this).attr('href')
        if (!href) return
        const target = $(href)
        if (!(target && target.length && target.offset)) return
        // check if device is mobile
        if (window.matchMedia('only screen and (max-width: 760px)').matches) {
            const offset = target.offset()?.top || mobileHeaderHeight
            $('html,body').animate({
                scrollTop: offset - mobileHeaderHeight,
            }, 'linear')
        } else {
            const offset = target.offset()?.top || headerHeight
            $('html,body').animate({
                scrollTop: offset - headerHeight,
            }, 'linear')
        }
    })
}
