import Swiper from "swiper";
import {Navigation} from "swiper/modules";

export default function InitReviewSlider() {
  const reviewSliders = document.querySelectorAll<HTMLElement>(".reviews__slider");

  for (const reviewSlider of reviewSliders) {
    const swiperContainer = reviewSlider.querySelector<HTMLElement>(".swiper");

    if (!swiperContainer) {
      continue;
    }

    new Swiper(swiperContainer, {
      navigation: {
        nextEl: reviewSlider.querySelector<HTMLElement>(".reviews__next"),
        prevEl: reviewSlider.querySelector<HTMLElement>(".reviews__prev"),
      },
      autoplay: false,
      loop: true,
      spaceBetween: 20,
      modules: [Navigation],
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        800: {
          slidesPerView: 2,
        },
        1350: {
          slidesPerView: 3,
        },
        1750: {
          slidesPerView: 4,
        },
      },
    });
  }
}
