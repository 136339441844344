// @ts-ignore
import Colcade from "colcade";

function handleScrollTransition(headerWrapper: Element | null) {

  const heroImageRect = document.querySelector('.hero-section-image__text-content')?.getBoundingClientRect().top;
  const headerWrapperRect = headerWrapper?.getBoundingClientRect().height;
  let box = 0;

  // show at 10% based on window height or when touching hero image text
  let transitionScrollY = window.innerHeight * 0.1;
  if (heroImageRect && headerWrapperRect) {
    box = heroImageRect - headerWrapperRect;
    // box > 0: must not touch the header --> glass/blur effect
    // box < transitionScrollY: transition must toggle earlier
    if (box > 0 && box < transitionScrollY) {
      transitionScrollY = heroImageRect - headerWrapperRect;
    }
  }

  // transparent header scroll feature
  if (headerWrapper) {
    // replace all scroll function by using onscroll instead of addEventListener
    window.onscroll = function() {
      if (headerWrapper.classList.contains('header-wrapper--no-transparent')) return;

      if (window.scrollY > transitionScrollY) {
        headerWrapper.classList.remove('header-wrapper--transparent')
      } else {
        headerWrapper.classList.add('header-wrapper--transparent')
      }
    }
  }
}


export default function InitAestheticHeader() {
  const headerWrapper = document.querySelector('.header-wrapper')

  window.addEventListener('load', function () {
    handleScrollTransition(headerWrapper);
  });

  window.addEventListener('resize', function () {
    handleScrollTransition(headerWrapper);
  });

  window.addEventListener('orientationchange', function () {
    handleScrollTransition(headerWrapper);
  });


  var transparencyColor = '';
  ['pampas', 'main', 'black'].forEach(color => {
    if (headerWrapper?.classList.contains(color)) transparencyColor = color
  })

  const headerBright = () => {
    if (!!headerWrapper) {
      headerWrapper.classList.remove('header-wrapper--megamenu')

      // Change logo color
      headerWrapper.querySelector('.header-logo-pampas')?.classList.add('display-none')
      headerWrapper.querySelector('.header-logo-main')?.classList.remove('display-none')

      // Change button color
      const headerButtonTrans = headerWrapper.querySelector('.header-button-transparent')
      headerButtonTrans?.classList.add(transparencyColor)
      headerButtonTrans?.classList.remove('pampas')

      const headerButton = headerWrapper.querySelector('.header-button')
      headerButton?.classList.add('main')
      headerButton?.classList.remove('pampas')
    }
  }

  const headerDark = () => {
    if (!!headerWrapper) {
      headerWrapper.classList.add('header-wrapper--megamenu')
      const headermenuHeader = document.querySelector('.headermenu-header')
      // do not toggle the bg canvas for mobile menu
      // equals @include breakpoint('tablet')
      if (window.matchMedia('(min-width: 768px)').matches) {
        headermenuHeader?.classList.add('display-none')
      } else {
        // set new div child element to show megamenu category
        const currentMegamenuCategory = document.querySelector('.megamenu--open .megamenu__category-current')
        if (currentMegamenuCategory) headermenuHeader?.appendChild(currentMegamenuCategory.cloneNode(true))
      }

      // Change logo color
      headerWrapper.querySelector('.header-logo-main')?.classList.add('display-none')
      headerWrapper.querySelector('.header-logo-pampas')?.classList.remove('display-none')

      // Change button color
      const headerButtonTrans = headerWrapper.querySelector('.header-button-transparent')
      headerButtonTrans?.classList.add('pampas')
      headerButtonTrans?.classList.remove(transparencyColor)

      const headerButton = headerWrapper.querySelector('.header-button')
      headerButton?.classList.add('pampas')
      headerButton?.classList.remove('main')

      headerWrapper.classList.remove('header-wrapper--header-menu')
    }
  }

  const handleHeaderColor = () => {
    const megamenu = document.querySelector('.megamenu')
    if (megamenu && megamenu.classList.contains('megamenu--open')) {
      headerDark()
    } else {
      headerBright()
    }
  }

  document.querySelector('.headerlink--has_children')?.addEventListener('click', (e: Event) => {
    e.preventDefault()
    const currentTarget = e.currentTarget as HTMLElement
    const megamenu = currentTarget.nextElementSibling
    megamenu?.classList.toggle('megamenu--closed')
    megamenu?.classList.toggle('megamenu--open')

    handleHeaderColor()
  })

  /**
   *  Open Menu if clicked on the burger icon
   */
  document.querySelector('.headermenu-button')?.addEventListener('click', (e: Event) => {
    const root = document.documentElement
    root.classList.toggle('header-open')
    const currentTarget = e.currentTarget as HTMLElement

    currentTarget?.classList.toggle('headermenu-button--open')
    currentTarget?.classList.toggle('headermenu-button--closed')

    // diplays the section of the headermenu in the initial header (keeping the same button element)
    const headermenuHeader = document.querySelector('.headermenu-header')
    if (currentTarget.classList.contains('headermenu-button--closed')) {
      currentTarget.classList.add(transparencyColor);
      headermenuHeader?.classList.add('display-none')
      // remove div child element to hide megamenu category
      headermenuHeader?.replaceChildren();
    } else {
      headermenuHeader?.classList.toggle('display-none')
    }

    if (headerWrapper) {
      if (currentTarget.classList.contains('headermenu-button--open')) {
        currentTarget.classList.remove(transparencyColor);
        headerWrapper.classList.add('header-wrapper--header-menu')
      } else {
        headerWrapper.classList.remove('header-wrapper--header-menu')
      }
    }

    const headermenuMain = document.querySelector('.headermenu-main')
    headermenuMain?.classList.toggle('headermenu-main--open')
    headermenuMain?.classList.toggle('headermenu-main--closed')

    if (root?.classList.contains('header-open')) {
      document.querySelectorAll('.megamenu').forEach(megamenu => {
        megamenu.classList.remove('megamenu--open')
        megamenu.classList.add('megamenu--closed')
      })
    }
    headerBright()
  })


    // MOBILE MENU
    document.querySelectorAll('.megamenu__category-title').forEach(categoryTitle => {
        categoryTitle.addEventListener('click', (e: Event) => {
            const currentTarget = e.currentTarget as HTMLElement
            currentTarget.parentElement?.classList.toggle('category--open')
        })
    })

    // INITIALIZE MEGA MENU MASONRY
    let colc = new Colcade( '.megamenu', {
        columns: '.megamenu__categories',
        items: '.megamenu__category'
    });
}
