import $ from 'jquery';
import Swiper from 'swiper'
import { Navigation, Manipulation } from 'swiper/modules'
import { FilterSelectEvent } from './filter-select-event'
import { filterPersonDomElements } from "./person-list-filtering";

export default function InitPersonList() {
  // person filter and search
  const personListElement = document.querySelector('.aesthetics-person-list');
  if (!personListElement) {
    return;
  }

  let selectedLocation =
    (personListElement.querySelector('select#person-filter-location') as HTMLSelectElement)?.value ||
    0;
  let selectedDepartment =
    (personListElement.querySelector('select#person-filter-department') as HTMLSelectElement)?.value ||
    0;
  let selectedName =
    (personListElement.querySelector('input#person-filter-name') as HTMLInputElement)?.value || '';

  /**
   * @type {{name: string, uid: number, root: number}[]}
   */
  let selectedSpecialFachgebiete: any[] = [];
  let isSomeSpecialFachgebietSelected = false;

  const filterPersons = () => {
    const hasAnyMatch = filterPersonDomElements(
      personListElement,
      selectedLocation,
      selectedDepartment,
      selectedName,
      isSomeSpecialFachgebietSelected ? selectedSpecialFachgebiete : []
    )

    personListElement
      .querySelectorAll('.col-no-results')
      .forEach(noResultsElement => (noResultsElement as HTMLElement).hidden = hasAnyMatch)
  };

  const resetFilters = (type: string) => {
    $('#person-filter-' + type).each(function () {
      const $filter = $(this)

      if ($filter.is('[data-options')) {
        const currentValue = $filter.val()
        $filter.html($filter.data('options'))
        currentValue && $filter.val(currentValue)
      } else {
        $filter.attr('data-options', $filter.html())
      }

      $filter.selectmenu('refresh')
    })
  }

  const updateFilter = (type: string) => {
    resetFilters(type)

    // remove the useless options of the form element of the given type.
    // useless in this context means, that if the option is selected, no results will show up.
    const usefulFilterOptions = new Set()
    usefulFilterOptions.add('0')

    /**
     * @type {NodeListOf<HTMLElement>}
     */
    const listItems = document.querySelectorAll('.doctor__col')

    for (const listItem of listItems) {
      if (!((listItem as HTMLElement).offsetWidth > 0 ||
        (listItem as HTMLElement).offsetHeight > 0 ||
        listItem.getClientRects().length > 0)) {
        continue
      }

      const dataPayload = (listItem as HTMLElement).dataset[type === 'location' ? 'standorte' : 'fachgebiete']

      if (!dataPayload || dataPayload === null || dataPayload === '') {
        continue
      }

      for (const listItemFilterValue of dataPayload.split(',')) {
        usefulFilterOptions.add(listItemFilterValue)
      }
    }

    /**
     * @type {HTMLSelectElement}
     */
    const filterElement = document.querySelector(`select#person-filter-${type}`)
    if (filterElement?.querySelectorAll('option')) {
      for (const optionElement of filterElement.querySelectorAll('option')) {
        if (usefulFilterOptions.has(optionElement.value)) {
          continue
        }

        optionElement.remove()
      }
    }

    filterElement && $(filterElement).selectmenu('refresh')
  }

  $('#person-filter-location').on('selectmenuchange', function () {
    selectedLocation = $(this).val() as (string | number)
    if (selectedLocation === '0') resetFilters('location')
    filterPersons()
    updateFilter('department')
  })

  $('#person-filter-department').on('selectmenuchange', function () {
    // dude, idk. I spent more than an hour trying to figure out how to listen
    // for that dump event, but there's nothing there.
    this.dispatchEvent(new FilterSelectEvent())
    selectedDepartment = $(this).val() as (string | number)
    if (selectedDepartment === '0') resetFilters('department')
    filterPersons()
    updateFilter('location')
  })

  $('#person-filter-name').on('input', function () {
    selectedName = ($(this).val() as string)?.toLowerCase()
    filterPersons()
  })

  const personFilterSpecialFachgebietId = 'person-filter-special-fachgebiet'
  const specialFachgebietFilterInputElement = document.getElementById(personFilterSpecialFachgebietId)
  /**
   * @type {{name: string, uid: number, root: number}[]}
   */
  const originalFachgebieteList = specialFachgebietFilterInputElement !== null ?
    JSON.parse(specialFachgebietFilterInputElement.getAttribute('data-special-fachgebiet-options') || "") : []

  const specialFachgebietSelectSearchSuggestionIdBase = 'person-filter-special-fachgebiet-suggestion'
  const specialFachgebietSuggestSelectElement = document.getElementById(`${specialFachgebietSelectSearchSuggestionIdBase}`)
  const specialFachgebietSuggestMenuElement = document.getElementById(`${specialFachgebietSelectSearchSuggestionIdBase}-menu`)
  let isSpecialFachgebietAutoCompleteActive = false

  specialFachgebietFilterInputElement?.addEventListener('focusin', () => {
    isSpecialFachgebietAutoCompleteActive = true
    updateFachgebietSelectSearchSuggestionState()
  })

  specialFachgebietFilterInputElement?.addEventListener('focusout', e => {
    if (e.relatedTarget === specialFachgebietSuggestMenuElement) {
      return;
    }

    isSpecialFachgebietAutoCompleteActive = false
    updateFachgebietSelectSearchSuggestionState()
  })

  const $specialFachgebietSelect = $(`#${specialFachgebietSelectSearchSuggestionIdBase}`)

  $specialFachgebietSelect.on('selectmenuchange', function (event, ui) {
    const target = event.target as HTMLInputElement;
    if ((specialFachgebietFilterInputElement as HTMLInputElement)?.value) {
      (specialFachgebietFilterInputElement as HTMLInputElement).value = target.value
    }
    handleSpecialInputChange(target.value)
  })

  function updateFachgebietSelectSearchSuggestionState() {
    $specialFachgebietSelect.selectmenu('refresh')
    if (isSpecialFachgebietAutoCompleteActive && selectedSpecialFachgebiete.length !== 0) {
      $specialFachgebietSelect.selectmenu('open')
    } else {
      $specialFachgebietSelect.selectmenu('close')
    }
  }

  function updateFachgebietSelectSearchSuggestion() {
    // remove children
    if (!!specialFachgebietSuggestSelectElement) {
      specialFachgebietSuggestSelectElement.innerText = ''
    }


    if (selectedSpecialFachgebiete.length === 0) {
      return;
    }

    const classNameBase = 'autocomplete-suggestion';

    // if we add something we must add nothing, too
    (() => {
      const element = document.createElement('option')
      element.className = `${classNameBase}`
      element.innerText = ''
      element.setAttribute('data-index', "-1")
      element.value = ''
      specialFachgebietSuggestSelectElement?.appendChild(element)
    })()

    // add children
    for (let i = 0; i < selectedSpecialFachgebiete.length; i++) {
      const element = document.createElement('option')
      const item = selectedSpecialFachgebiete[i]
      element.className = `${classNameBase}`
      element.innerText = item.name
      element.setAttribute('data-index', i.toString())
      element.value = item.name
      specialFachgebietSuggestSelectElement?.appendChild(element)
    }
  }

  /**
   *
   * @param {string} textInput
   */
  const handleSpecialInputChange = (textInput: string) => {
    selectedSpecialFachgebiete = []

    if (!!textInput) {
      isSomeSpecialFachgebietSelected = true;
      const lowerCasedValue = textInput.toLowerCase()

      for (let i = 0; i < originalFachgebieteList.length; i++) {
        const currentItem = originalFachgebieteList[i]
        const tokens = currentItem.name.toLowerCase().split(" ")
        const selectedDepartmentInt = parseInt(selectedDepartment as string)
        const matchesFachbereichFilter = selectedDepartmentInt === 0 || currentItem.root === selectedDepartmentInt
        if (matchesFachbereichFilter && (
          currentItem.name?.toLowerCase().startsWith(lowerCasedValue)
          || anyStartsWith(tokens, lowerCasedValue)
        )) {
          selectedSpecialFachgebiete.push(currentItem)
        }
      }
    } else {
      isSomeSpecialFachgebietSelected = false;
    }

    updateFachgebietSelectSearchSuggestion()
    updateFachgebietSelectSearchSuggestionState()
    filterPersons()
  }

  handleSpecialInputChange((specialFachgebietFilterInputElement as HTMLInputElement)?.value)
  specialFachgebietFilterInputElement && $(specialFachgebietFilterInputElement).on('input', function () {
    handleSpecialInputChange((this as HTMLInputElement).value)
  })

  /**
   * Return true, if any item of the strList starts with str.
   *
   * @return {boolean}
   * @param {string[]} strList
   * @param {string} str
   */
  function anyStartsWith(strList: string[], str: string) {
    for (let i = 0; i < strList.length; i++) {
      if (strList[i]?.startsWith(str)) {
        return true
      }
    }
    return false
  }

  const personListSliders = document.querySelectorAll<HTMLElement>('.aesthetics-person-list--slider')

  for (const personListSlider of personListSliders) {
    const personSwiperElement = personListSlider.querySelector<HTMLElement>('.swiper-personlist')

    if (!personSwiperElement) {
      continue;
    }

    const personSlides = personListSlider.querySelectorAll<HTMLElement>('.swiper-slide')
    const personSwiper = new Swiper(personSwiperElement, {
      slidesPerView: 1.5,
      slidesPerGroup: 1,
      spaceBetween: 30,
      breakpoints: {
        768: {
          slidesPerView: 2.2,
          slidesPerGroup: 1,
          spaceBetween: 30,
        },
        1023: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 30,
        }
      },
      navigation: {
        nextEl: '.aesthetics-person-list__next',
        prevEl: '.aesthetics-person-list__prev',
      },
      loop: false,
      modules: [Navigation, Manipulation]
    })

    const listNavigation = personListSlider.querySelector<HTMLElement>('.aesthetics-person-list__navigation');

    if (listNavigation) {
      const documentWidth = document.body.clientWidth
      listNavigation.hidden = documentWidth > 1023
        ? personSlides.length <= 3
        : personSlides.length > 4
    }

    const filterPersonsInSlider = () => {
      personSwiper.removeAllSlides()
      for (const personSlide of personSlides) {
        const currentEntryLocations = (personSlide.dataset['standorte'] + '').split(',')

        if (selectedLocation === 0 || selectedLocation === "0" || currentEntryLocations.includes(selectedLocation + '')) {
          personSwiper.appendSlide(personSlide)
        }
      }
      personSwiper.update()

      $('.doctor-person-links-filter').find('.links-filter__option').removeClass('links-filter__option--selected')
    }

    $('.doctor-person-links-filter').on('click', '.links-filter__option', function () {
      selectedLocation = $(this).data('uid')

      if (!$(this).hasClass('links-filter__option--selected')) {
        filterPersonsInSlider()
        $(this).addClass('links-filter__option--selected')
      }
    })
  }

  document.querySelectorAll('.doctor-person__image-container').forEach((element) => {
    const openIcon = element.querySelector('.doctor-person__open-popup')
    const closeIcon = element.querySelector('.doctor-person__close-popup')
    const popUp = element.querySelector('.doctor-person__contact-container')
    const img = element.querySelector('.doctor-person__image')

    if (openIcon && closeIcon && popUp && img) {

      const onClick = (e: Event) => {
        popUp.classList.toggle('hidden')
        openIcon.classList.toggle('hidden')
        closeIcon.classList.toggle('hidden')
        img.classList.toggle('opacity')
        e.preventDefault()
      }

      openIcon.addEventListener('click', (e) => onClick(e))
      closeIcon.addEventListener('click', (e) => onClick(e))
    }
  })
}
