function isOverflown(element: HTMLElement) {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

export default function InitHeaderSiteIntro() {

  const headerSiteIntros = document.querySelectorAll('.header-site-intro');

  if (!!headerSiteIntros) {
    headerSiteIntros.forEach(headerSiteIntro => {
      const contentText = headerSiteIntro.querySelector<HTMLElement>('.header-site-intro__content-text');

      if (contentText && isOverflown(contentText)) {
        const autoflowToggle = contentText.parentElement?.querySelector('.autoflow-toggle');
        contentText.classList.add('more');
        autoflowToggle?.classList.add('more');
        autoflowToggle?.addEventListener('click', e => {
          contentText.classList.toggle('less');
          autoflowToggle.classList.toggle('less');
          contentText.classList.toggle('more');
          autoflowToggle.classList.toggle('more');
        })
      }
    })
  }
}
