import { Loader } from '@googlemaps/js-api-loader';

interface Location {
    uid: number;
    latitude?: number;
    longitude?: number;
    street: string;
    zip: string;
    city: string;
    parent?: number;
    groups: number[];
  marker?: google.maps.Marker;
}

interface PallasMaps {
    map: google.maps.Map | null;
    locations: Location[];
    infoWindows: google.maps.InfoWindow[];
    bounds: google.maps.LatLngBounds | null;
    initMap: () => void;
    initLocations: () => void;
    addMarker: (location: Location) => void;
    addMarkerToMap: (location: Location, position: google.maps.LatLngLiteral) => void;
    addInfoWindow: (location: Location) => void;
    getLocationInfoWindowContent: (location: Location) => HTMLElement;
    setEvents: () => void;
    updateMarkers: (isEvent: boolean, chosenFilter: number) => void;
    updateChildLocationMarkers: (chosenFilter: number, parentLocationUid: number, isParentVisible: boolean) => void;
    inGroup: (locationGroups: number[], activeGroups: number[]) => boolean;
    disableBounds: () => void;
}

const colors = {
    white: '#ffffff',
    black: '#000000',
    grey95: '#f2f2f2',
    platinum: '#e9e9e7',
    grey: '#e0e0de',
    bluegrey: '#9ca5b3',
    greengrey: '#c8d7d4',
    darkblue: '#17263c',
}

const mapStyles = [
    { elementType: 'geometry', stylers: [{ color: colors.white }] },
    { elementType: 'labels.text.stroke', stylers: [{ visibility: 'off' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: colors.black }] },
    {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: colors.black }],
    },
    {
        featureType: 'landscape',
        elementType: 'labels',
        stylers: [{ 'visibility': 'off' }],
    },
    {
        featureType: "landscape.man_made.building",
        elementType: "geometry.fill",
        stylers: [
            { color: colors.platinum }]
    },
    {
        featureType: "landscape.man_made.building",
        elementType: "geometry.stroke",
        stylers: [
            { color: colors.grey }]
    },
    {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: colors.grey95 }],
    },
    {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ color: colors.grey95 }],
    },
    {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: colors.bluegrey }],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: colors.grey95 }],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: colors.grey95 }],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: colors.bluegrey }],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: colors.grey95 }],
    },
    {
        featureType: 'transit.station',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: colors.greengrey }],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: colors.bluegrey }],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{ color: colors.darkblue }],
    },
]

const pallasMaps: PallasMaps = {
    map: null,
    locations: [],
    infoWindows: [],
    bounds: null,

    initMap() {
        if (document.getElementById('map')) {
            pallasMaps.bounds = new google.maps.LatLngBounds();
            pallasMaps.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
                center: { lat: 46.9, lng: 7.4 },
                zoom: 8,
                styles: mapStyles
            });
            pallasMaps.initLocations();
            pallasMaps.setEvents()
        }
    },

    initLocations() {
        var mapElement = document.getElementById('map');
        pallasMaps.locations = JSON.parse(mapElement?.dataset["locations"] as string);

        pallasMaps.locations.forEach((location) => {
            pallasMaps.addMarker(location);
        });
    },

    addMarker(location) {
        if (!!location.latitude && !!location.longitude) {
            pallasMaps.addMarkerToMap(location, { lat: location.latitude, lng: location.longitude });
            return;
        }

        var geocoder = new google.maps.Geocoder();
        var address = location.street + ' ' + location.zip + ' ' + location.city;

        geocoder.geocode({ address: address }, function (results: any, status: any) {
            if (status === 'OK' && results && results[0]) {
                pallasMaps.addMarkerToMap(location, results[0].geometry.location as any);
            } else if (status === 'OVER_QUERY_LIMIT') {
                window.setTimeout(function () {
                    pallasMaps.addMarker(location);
                }, 100);
            } else {
                console.log('Geocode was not successful for the following reason: ' + status);
            }
        });
    },

    addMarkerToMap(location, position) {
        location.marker = new google.maps.Marker({
            position: position,
            map: null,
            icon: {
                url: '/typo3conf/ext/pallas_aesthetics/Resources/Public/Icons/location.svg',
                scaledSize: new google.maps.Size(35, 35),
            },
        });

        pallasMaps.addInfoWindow(location);

        if (pallasMaps.locations.length === 1 && pallasMaps.map) {
            pallasMaps.map.setCenter(position);
            pallasMaps.map.setZoom(17);
        } else if (pallasMaps.bounds && pallasMaps.map) {
            pallasMaps.bounds.extend(position);
            pallasMaps.map.setCenter(pallasMaps.bounds.getCenter());
        }

        pallasMaps.updateMarkers(false, -1);
    },

    addInfoWindow(location) {
        const infoWindow = new google.maps.InfoWindow({
            content: pallasMaps.getLocationInfoWindowContent(location),
            pixelOffset: new google.maps.Size(-150, 80),
        });
        pallasMaps.infoWindows.push(infoWindow);

        location.marker?.addListener('click', function () {
            pallasMaps.infoWindows.forEach(function (iw) {
                iw.close();
            });
            infoWindow.open(pallasMaps.map, location.marker);
        });

        if (pallasMaps.locations.length === 1 && pallasMaps.map) {
            google.maps.event.addListenerOnce(pallasMaps.map, 'tilesloaded', function () {
                infoWindow.open(pallasMaps.map, location.marker);
            });
        }
    },

    getLocationInfoWindowContent(location) {
        return document.getElementById(`map-location-${location.uid}`) as HTMLElement;
    },

    setEvents() {
        //do not recenter map after user change
        window.setTimeout(function () {
            pallasMaps.map?.addListener('drag', pallasMaps.disableBounds);
            pallasMaps.map?.addListener('zoom_changed', pallasMaps.disableBounds);
        }, 100);
    },

    updateMarkers(isEvent, chosenFilter) {
        if (isEvent === true) {
            pallasMaps.infoWindows.forEach(function (iw) {
                iw.close();
            });
        }

        pallasMaps.locations.forEach(function (location) {
            if (location.parent) {
                return;
            }
            if (location.hasOwnProperty('marker')) {
                if (pallasMaps.inGroup(location.groups, chosenFilter !== -1 ? [chosenFilter] : [])) {
                    location.marker?.setMap(pallasMaps.map);
                    pallasMaps.updateChildLocationMarkers(chosenFilter, location.uid, true);
                } else {
                    location.marker?.setMap(null);
                    pallasMaps.updateChildLocationMarkers(chosenFilter, location.uid, false);
                }
            }
        });
    },
    updateChildLocationMarkers(chosenFilter, parentLocationUid, isParentVisible) {
        pallasMaps.locations.forEach(function (location) {
            if (location.parent == parentLocationUid && location.hasOwnProperty('marker')) {
                if (pallasMaps.inGroup(location.groups, chosenFilter !== -1 ? [chosenFilter] : []) && !isParentVisible) {
                    location.marker?.setMap(pallasMaps.map);
                } else {
                    location.marker?.setMap(null);
                }
            }
        });
    },

    inGroup(locationGroups, activeGroups) {
        if (activeGroups.length === 0) {
            return true;
        }
        let found = false;
        locationGroups.forEach(function (locationGroupId) {
            activeGroups.forEach(function (activeGroupId) {
                if (locationGroupId === activeGroupId) {
                    found = true;
                    return false;
                }
                else return true
            });
        });

        return found;
    },
    disableBounds() {
        if (pallasMaps.bounds) {
            pallasMaps.bounds = null;
        }
    },
};

function lazy_maps() {
    const options = {
        rootMargin: '50px',
        threshold: 0,
    };

    const map = document.getElementById('map');

    const observer = new IntersectionObserver(
        function (entries, self) {
            const isIntersecting =
                typeof entries[0]?.isIntersecting === 'boolean'
                    ? entries[0].isIntersecting
                    : (entries[0] ? entries[0].intersectionRatio > 0 : null);

            if (isIntersecting) {
              const loader = new Loader({
                apiKey: 'AIzaSyB2x-XUIZuvuSMHR6Hnj5T_wlZlO_8JjAo',
              })
              loader
                .importLibrary('maps')
                .then(() => {
                  initMap()
                })
                self.unobserve(map!);
            }
        },
        options
    );

    if (map) {
        observer.observe(map);
    }
}

export default function InitMap() {
    if (document.getElementById('mapsScript')) {
        pallasMaps.initMap();
    }
}

// Hacky approach to make the function available globally to use in the Google Maps callback
// Nobody likes that approach... don't do this at home, kids
// @ts-ignore
window.initMap = InitMap;

lazy_maps();
