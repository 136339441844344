const rootClassName = 'collapsible',
  toggleClassName = 'collapsible__toggle',
  contentClassName = 'collapsible__content',
  contentHiddenClassName = `${contentClassName}--hidden`,
  chevronClassName = 'content-clicker__icon',
  chevronClosedClassName = 'icon-aesthetics--plus',
  chevronOpenClassName = 'icon-aesthetics--minus',
  labelClassName = 'collapsible__label',
  labelOpenClassName = `${labelClassName}--open`

/**
 * @param {HTMLElement|HTMLDetailsElement} rootElement
 */
function showCollapsible(rootElement: HTMLElement | HTMLDetailsElement) {
  rootElement.querySelector(`.${contentClassName}`)?.classList.remove(contentHiddenClassName)
  rootElement.querySelector(`.${chevronClassName}`)?.classList.add(chevronOpenClassName)
  rootElement.querySelector(`.${chevronClassName}`)?.classList.remove(chevronClosedClassName)
  rootElement.querySelector(`.${labelClassName}`)?.classList.add(labelOpenClassName)

  if (rootElement instanceof HTMLDetailsElement && typeof rootElement.open === 'boolean') {
    rootElement.open = true
  }
}

/**
 * @param {HTMLElement|HTMLDetailsElement} rootElement
 */
function hideCollapsible(rootElement: Element | HTMLDetailsElement) {
  rootElement.querySelector(`.${contentClassName}`)?.classList.add(contentHiddenClassName)
  rootElement.querySelector(`.${chevronClassName}`)?.classList.remove(chevronOpenClassName)
  rootElement.querySelector(`.${chevronClassName}`)?.classList.add(chevronClosedClassName)
  rootElement.querySelector(`.${labelClassName}`)?.classList.remove(labelOpenClassName)

  if (rootElement instanceof HTMLDetailsElement && typeof rootElement.open === 'boolean') {
    rootElement.open = false
  }
}

/**
 * @param {HTMLElement|HTMLDetailsElement} rootElement
 * @return boolean
 */
function isCollapsibleOpen(rootElement: HTMLElement | HTMLDetailsElement) {
  if (!rootElement.classList.contains(rootClassName)) {
    throw new Error('Provided element is not a collapsible')
  }

  if (rootElement instanceof HTMLDetailsElement && typeof rootElement.open === 'boolean') {
    return rootElement.open === true
  }

  return !rootElement.querySelector(`.${contentClassName}`)?.classList.contains(contentHiddenClassName)
}

export default function InitCollapsible() {
  Array.from(document.getElementsByClassName(toggleClassName)).forEach(toggleElement => {
    toggleElement.addEventListener('click', (event) => {
      event.preventDefault()
      /**
       * @type {HTMLDivElement}
       */
      const currentToggleElement = event.target as HTMLElement
      let collapsibleRoot = currentToggleElement.parentElement

      if (collapsibleRoot && collapsibleRoot.classList.contains(toggleClassName)) {
        collapsibleRoot = collapsibleRoot.parentElement
        if (collapsibleRoot) {
          if (!isCollapsibleOpen(collapsibleRoot)) {
            showCollapsible(collapsibleRoot)
          } else {
            hideCollapsible(collapsibleRoot)
          }
        }
      }
    })
  });
}
