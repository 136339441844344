import Swiper from 'swiper';
import { Pagination, Autoplay } from 'swiper/modules';
import {SwiperOptions} from "swiper/types";

const colorSchemaClassNames = ['pampas', 'main', 'black'];

function changeBulletColor(swiper: Swiper) {
  // change color of bullets by setting CSS class from the current image text
  let colorSchemaClassName =
    swiper.wrapperEl.querySelector('.swiper-slide-active')?.querySelector(`:scope ${'.image-with-text__text'}`)?.getAttribute('class')
    || ""
  const imageSlider = swiper.el.parentElement;
  colorSchemaClassNames.forEach(className => {
    imageSlider?.classList.remove(className);
    if (colorSchemaClassName.includes(className)) colorSchemaClassName = className;
  })
  if (colorSchemaClassName) imageSlider?.classList?.add(colorSchemaClassName);
}

export default function InitImageSlider() {
  const sliderSettingsDefault: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
    autoplay: {
      delay: 5000,
    },
    modules: [Pagination, Autoplay],
    loop: true,
  }

  const sliderSettingsLocation: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination--location',
      clickable: true,
      bulletClass: 'swiper-pagination--location-bullet',
      bulletActiveClass: 'swiper-pagination--location-bullet-active',
      renderBullet: function (index, className) {
        const locationLabels = document.querySelectorAll<HTMLElement>('.swiper-slide--label')
        return (
          '<span class="' + className + '">'
          + (locationLabels[index]?.innerText || (index + 1))
          + '</span>'
        );
      },
    },
    modules: [Pagination],
    loop: false,
    slidesPerView: 1,
  }

  document.querySelectorAll('.image-slider').forEach((swiper) => {
    const isLocationSlider = !!swiper.querySelector('.swiper-pagination--location')
    const sliderSetting = isLocationSlider ? sliderSettingsLocation : sliderSettingsDefault

    let heroSlider = new Swiper(swiper.querySelector('.swiper-imageslider') as HTMLElement, sliderSetting)
    heroSlider.init()

    heroSlider.on('init', function (swiper) {
      changeBulletColor(swiper);
    });
    heroSlider.on('transitionEnd', function (swiper) {
      changeBulletColor(swiper);
    });
  })
}
