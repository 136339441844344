function handlePopUpWithLocalStorage(popUp: HTMLElement, storageIndex: string) {
  // don't open the pop-up if it was closed (local storage item has been set) already
  const wasClosed = localStorage.getItem(storageIndex);
  if (wasClosed) {
    return;
  }

  const closeButtons = popUp.querySelectorAll("[data-popup-action='close']")
  if (closeButtons.length === 0) {
    return;
  }

  for (const closeButton of closeButtons) {
    closeButton.addEventListener("click", function () {
      popUp.hidden = true;
      localStorage.setItem(storageIndex, "1");
    });
  }

  popUp.hidden = false;
}

export default function InitPopUp() {

  const allPushTeasers = document.querySelectorAll<HTMLElement>(".block--popup");
  if (allPushTeasers.length === 0) {
    return;
  }

  // only get the last teaser, because we don't want multiple pop-ups popping up
  const popUp = allPushTeasers[allPushTeasers.length - 1];
  if (!popUp) {
    return;
  }

  const popUpId = popUp.dataset["popupId"] ? `_${popUp.dataset["popupId"]}` : "";
  const localStorageIndex = `aestheticsPopupClosed${popUpId}`;

  let delay = parseInt(popUp.dataset["popupDelay"] ?? "");

  delay = isNaN(delay) || delay < 0 ? 0 : delay;

  setTimeout(() => {handlePopUpWithLocalStorage(popUp, localStorageIndex)}, delay);
}
